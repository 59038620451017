import React from 'react';
import DynamicMatch from '../../components/matches/Match';
import { CompetitionWrapperUWCL } from '../../components/layout/CompetionWrapperUWCL';
import { uwcl } from '../../data/competitions/uwcl';

const teams = [
  {code: 'ars', name: 'Arsenal'},
  {code: 'fcb', name: 'Barcelona'},
  {code: 'bmu', name: 'Bayern Munich'},
  {code: 'cel', name: 'Celtic'},
  {code: 'che', name: 'Chelsea'},
  {code: 'gal', name: 'Galatasaray'},
  {code: 'ham', name: 'Hammarby'},
  {code: 'juv', name: 'Juventus'},
  {code: 'lyo', name: 'Lyon'},
  {code: 'mci', name: 'Manchester City'},
  {code: 'mad', name: 'Real Madrid'},
  {code: 'rom', name: 'Roma'},
  {code: 'pol', name: 'St. Pölten'},
  {code: 'twe', name: 'Twente'},
  {code: 'val', name: 'Vålerenga'},
  {code: 'wol', name: 'Wolfsburg'},
]

const UWCLMatch = ({route}) => {
    return (<CompetitionWrapperUWCL competition={uwcl}>
        <DynamicMatch {...{route, teams}} comp="uwcl" />
    </CompetitionWrapperUWCL>)
}
export default UWCLMatch;
