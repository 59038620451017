import moment from "moment";
import React, {useState} from "react";
import { useAuth } from "../../util/withAuth";
import numeral from "numeral";
import MyTeamDynamic from "../../components/team/MyTeamDynamic";
import { CompetitionWrapperUWCL } from "../../components/layout/CompetionWrapperUWCL";
import { uwcl } from "../../data/competitions/uwcl-2024";
import data from "../../data/uwcl/fixtures/rounds/fixtureList.json";
import BottomNavBar from '../../components/layout/MCompetionWrapperUWCL.jsx';
import currentData from "../../data/uwcl/fixtures/rounds/currentRound";
import nextData from "../../data/uwcl/fixtures/rounds/nextRound";

const matchLink = dateString => moment(dateString).format("Do MMMM, h:mm A");

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const MyUWCLTeamD = () => {
  uwcl.init();
  return (
    <CompetitionWrapperUWCL competition={uwcl}>
      <div className="container">
        <h1>My Team</h1>
	<p>Start building your fantasy UWCL team below:
        <ul>
	  <li> Select your players on the 'Choose Players' tab</li>
	  <li> Select your captain and starting XI on the 'Manage Team' tab</li>
	  <li> Give your team a name, then click the green 'Save Team' button!</li>
	  </ul>
	</p>
	<div className="centerBox">
	  <h5>Building your UWCL fantasy team</h5>
        <iframe width="560" height="315"  src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>

        <MyTeamDynamic {...uwcl} />

              <div className="container">
                  <p></p>
                  <p align="center">
                      <b>
                          Players who show up in red may not play next round. Check out our{" "}
                          <a href="https://blog.sheplays.com.au/uwcl-injuries/">Injuries</a> and
            <a href="https://blog.sheplays.com.au/uwcl-january-2021-transfers/">Transfers</a> pages for more details!
          </b>
                  </p>
              </div>


	<h4>Next rounds fixtures</h4>

	<div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Next Round</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {nextData.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td>{ground}</td>
                  <td align="right">{home} <img hspace="10" src={`/img/uwcl/badges/${hp}`} width="40"/></td>
                  <td>{matchLink(`${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/uwcl/badges/${ap}`} width="40"/> {away}</td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
        <p>
          All times in your browser's local time (GMT {moment().format("Z")}).
        </p>
        </div>

	<div className="centerBox">
          <h5>Building your FAUWCL fantasy team</h5>
        <iframe width="560" height="315"  src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>


        <h3>Selecting your players</h3>
        <p>
          Use the ‘Choose Players’ tab to select the 15 players that will make
          up your squad. You can browse the list, search by the player name, or
          filter the list by position or team. When you see the player you want,
          tick the box to the left of their name. They will then appear under
          the ‘Manage Team’ tab. If you change your mind, simply click on the x
          next to their fantasy price. If your team is over budget, or you do
          not fulfil the position requirements, an error message will appear.
        </p>
        <h3>Manage your team</h3>
        <p>
          Once you have picked your players, you then need to manage your team!
          This includes picking a Captain (who will earn double points) and a
          Vice Captain (who will earn double points if your captain doesn’t
          play). To re-order players, simply click on the drag handle (3 lined
          next to a players name) and pull them higher or lower on your list.
        </p>
        <p>
          You then need to pick the rest of your starting eleven. Again you can
          drag the players you want to start up the list using the drag handles.
          These are the players that will earn you points! Your starting XI must
          fit one of the allowed formations. An error message will appear if
          your team formation is not allowed.
        </p>
        <p>
          The remaining 4 players will make up your bench. They will only
          receive points if someone from your starting team doesn’t play.
          Finally you need to pick your team name! Please keep your team name
          respectful.
        </p>
        <p>
          Once you have completed all of the above steps, you are ready to
          submit your team! If your team meets all of the requirements a green
          ‘Save Team’ button will appear. Click on the button to submit your
          team! You can continue to make changes to your squad up until the
          start of the season. Make sure to keep an eye on the latest transfer
          and injury news. Good luck!
        </p>


        <h4>Rules</h4>
        <p>
          You will select 15 players, which must include 2 Goalkeepers, 4
          Defenders, 4 Midfielders, 3 Forwards and 2 Free Choice players (any
          position). There are a few other rules as well:
        </p>
        <ul>
          <li>
            You have a total budget of{" "}
            {numeral(uwcl.totalBudget).format("$0,0")},
            individual player prices range from{" "}
            {numeral(uwcl.minPlayerPrice).format("$0,0")} to{" "}
            {numeral(uwcl.maxPlayerPrice).format("$0,0")}
          </li>
          <li>
            You can only have up to {uwcl.perClubLimit} players
            from one club
          </li>
          <li>
            You will select a captain for your team, who will earn double points
          </li>
          <li>
            You will choose your starting 11 (which includes your captain) and
            your bench (the remaining 4 players)
          </li>
          <li>
            You must include one goalkeeper, and your starting 11 must be in an
            allowed formation:
          </li>
          <ul>
            <li>3-5-2</li>
            <li>3-4-3</li>
            <li>4-3-3</li>
            <li>4-4-2</li>
            <li>5-3-2</li>
          </ul>
        </ul>
      </div>
    </CompetitionWrapperUWCL>
  );
};



const MyUWCLTeamM = () => {
  uwcl.init();
  return (<>
      <div className="container">
        <h3>My Team</h3>
        <p style={{fontSize:'0.8rem'}}>
          Welcome to the team selector! To learn how to get started building your fantasy UWCL team watch the video below, or check the <a href='/uwcl/m/how-to-play'> How to Play</a> page.
        </p>

        <MyTeamDynamic {...uwcl} />

              <div className="container">
                  <p></p>
                  <p align="center">
                      <b>
                          Players who show up in red may not play next round. Check out our{" "}
                          <a href="https://blog.sheplays.com.au/uwcl-injuries/">Injuries</a> and
            <a href="https://blog.sheplays.com.au/uwcl-january-2021-transfers/">Transfers</a> pages for more details!
          </b>
                  </p>
              </div>


        <h4>Next rounds fixtures</h4>
        <div className="container">
        <table className="table table-striped">
          <thead className="thread-dark">
            <tr>
              <th scope="col">Next Round</th>
              <th scope="col" />
              <th scope="col" />
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
          {nextData.map(
                  ({
                          home,
                          hp,
                          away,
                          ap,
                          ground,
                          id,
                          date
                }) => (
                <tr key={home}>
                  <td align="right"> <img hspace="10" src={`/img/uwcl/badges/${hp}`} width="40"/></td>
                  <td>{matchLink(`${date}`)}</td>
                  <td align="left"><img hspace="10" src={`/img/uwcl/badges/${ap}`} width="40"/> </td>
                  <td> </td>
                </tr>
                )
                )}
          </tbody>
        </table>
        <p style={{fontSize:'0.8rem'}}>
          All times in your browser's local time (GMT {moment().format("Z")}).
        </p>
        </div>
	<p style={{textAlign:'center',color:'#4e579b'}}>Build your fantasy UWCL team!</p>
        <iframe width="100%" height="255" src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>


	</div>

	<BottomNavBar/>
	</>
  );
};


const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 800;

  return width < breakpoint ? <MyUWCLTeamM /> : <MyUWCLTeamD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


