import React, { useState } from 'react';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWSL } from '../../components/layout/CompetionWrapperWSL';
import BottomNavBar from '../../components/layout/MCompetionWrapperWSL.jsx';
import { Input } from "reactstrap";
import { wsl } from '../../data/competitions/wsl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow, TableColumnResizing,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/wsl/playerProfiles/allSeasons/allSeasonList24"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

export default () => {

const columns = [
    { title: 'Rank', name: 'rank' },
    { title: 'Player Name', name: 'name' },
    { title: 'Position', name: 'position' },
    /*{ title: 'Club', name: 'Club' },*/
    { title: '19/20', name: 'points19'},
    { title: '20/21', name: 'points20'},
    { title: '21/22', name: 'points21'},
    { title: '22/23', name: 'points22'},
    { title: '23/24', name: 'points23'},
    {
        title: 'Total',
        name: 'totalPoints',
        getCellValue: t => t.totalPoints || 0
    }
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>GK</option>
      <option>DF</option>
      <option>MF</option>
      <option>FW</option>
    </Input>
  </th>
);

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'nationality') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'position') {
        return PositionPicker(props);
    }
    return <th />
};

const [defaultColumnWidths] = useState([
        {columnName: 'rank', width: 80},
        {columnName: 'name', width:200},
        {columnName: 'position', width:100},
	{columnName: 'points19', width:80},
	{columnName: 'points20', width:80},
	{columnName: 'points21', width:80},
        {columnName: 'points22', width:80},
        {columnName: 'points23', width:80},
	{columnName: 'totalPoints', width:80}
]);
const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);


return ( <>
    <div className="container clearfix mt-32">
    </div>
    <div className="container" style={{fontSize:'0.7rem'}}>
        <Grid rows={data.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
	    <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
	<BottomNavBar/>
</>
);
};
