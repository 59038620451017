import React, { useState } from 'react';
import { useAuth } from "../../util/withAuth";
import { Auth, Hub } from "aws-amplify";
import moment from 'moment';
import { CompetitionWrapperUWCL } from '../../components/layout/CompetionWrapperUWCL';
import { uwcl } from '../../data/competitions/uwcl';
import BottomNavBar from '../../components/layout/MCompetionWrapperUWCL.jsx';
import { SiKoFi } from "react-icons/si";
import { AiOutlineInstagram} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";


const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const UWCLLandingPageD = () => {
        const currentUser = useAuth()
        return(<CompetitionWrapperUWCL competition={uwcl}>

<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/uwcl/uwcl3.png" alt="" />
	    <figcaption align="center"><a href="https://www.flickr.com/photos/gotbphotography/albums/with/72177720299027065" title = "GirlsontheBall" target="blank">GirlsontheBall</a><br/>All rights reserved</figcaption>
      </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">ShePlaysUWCL</h2>
              <p style={{textAlign:'justify'}}>
              Welcome to our inaugural ShePlays UWCL Competition! This is where you can find all of the information you might need to help build your UEFA Women's Champions League dream team. 
              </p>

              <p style={{textAlign:'justify'}}>
              Team selection for the 2024/25 season is now open! The deadline to get your team in for round 1 is {localDate("2024-10-08 15:45:00+00")}. To start building 
              your team, create a free ShePlays account, and then click on the My UWCL Team button below.
              </p>
             <div className="centerBox"> 
              {currentUser ? (
        <a href="/uwcl/my-team" className="btn btn-secondary btn-xl rounded-pill mt-1" style={{color:'white'}}>
          My UWCL Team </a>
) : (
        <a onClick={() => Auth.federatedSignIn()} href="#" className="btn btn-secondary btn-xl rounded-pill mt-1" style={{color:'white'}}>
          Log In </a>)}
            </div>
            </div>
          </div>
        </div>
      </div>

  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="p-5">
		<img className="img-fluid rounded-circle" src="img/uwcl/uwcl2.png" alt="" />
            <figcaption align="center"><a href="https://www.flickr.com/photos/gotbphotography/albums/with/72177720299027065" title = "GirlsontheBall" target="blank">GirlsontheBall</a><br/>All rights reserved</figcaption>
    </div>
          </div>
          <div className="col-lg-6">
            <div className="p-5">
              <h2 className="display-4">How to play</h2>
               <p style={{textAlign:'justify'}}>
		Once you have created an account you can start building your UWCL dream team on our team selector:
		<ul>
		<li> Select the 15 players who will make up your dream team - but stay within the initial budget cap of $8,000,000! </li>
		<li> Each week, chose your starting XI, captain, and vice captain, who will earn fantasy points during UWCL matches.</li>
                <li> Improve your team during the transfer windows by bringing new players in, to help your team climb to the top of the leaderboards!</li>
		</ul>
		</p>

            </div>
          </div>
        </div>
      </div>
  </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
		<img className="img-fluid rounded-circle" src="img/uwcl/uwcl1.png" alt="" />
            <figcaption align="center"><a href="https://www.flickr.com/photos/gotbphotography/albums/with/72177720299027065" title = "GirlsontheBall" target="blank">GirlsontheBall</a><br/>All rights reserved</figcaption>

    </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">Player Research</h2>
             <p style={{textAlign:'justify'}}>
             We have plenty of resources to help you learn more about the players in the league, and to help you  chose the best players 
		for your team:
		<ul>
		<li>A collection of detailed Player Profiles can be found on our <a href="/uwcl/databse" target="blank">Player Database</a>.</li>
		<li>Our <a href="/uwcl/stats" target="blank">Stats Center</a> provides a fantasy point break down of every player in the league.</li>
		<li>We also have a full collection of fantasy stats from all of our outher competitions, including the WSL, NWSL, W-League, the UEFA Euro's and the 2019 and 2023 World Cups! </li>
		</ul>
		</p>

            </div>
          </div>
        </div>
      </div>
  </section>

		        <div className="leftBox">
        <h3 style={{textAlign:'center'}}>
                <b>Need help? Watch the videos below to find out how to get started! </b>

              </h3>
        </div>

		  <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
        <div className="centerBox">
        <h5>Build your UWCL fantasy team</h5>
        <iframe width="90%" height="255"  src="https://www.youtube.com/embed/7RWYStsWoQ0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
        <div className="col-lg-6 order-lg-1">
        <div className="centerBox">
        <h5>Create your free ShePlays account</h5>
        <iframe width="90%" height="255" src="https://www.youtube.com/embed/LevVeCEt3bc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
      </div>
     </div>




  <div className="container" align="center">
  <h3>Support ShePlays</h3>
        <p>Like what we do? Want to help us get bigger and better? Click on the button below to donate!<br/>
        <a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-kofi btn-s mt-1"><img src="/img/Ko-fi1.png" width="200"/></a><br/>
        Want to support us with your time instead of your money? Check out how you can be a part of the ShePlays team <a href="/join-us">here!</a>
    </p>
</div>
	<div className="container">
	    <p align="center"><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>     
	    <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white'}}></a></p>
	</div>
</CompetitionWrapperUWCL>);
};





const UWCLLandingPageM = () => {
        const currentUser = useAuth()
        return(<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>

    <header className="mastheadUWCL text-center text-white">
      <div className="masthead-contentUWCL">
        <div className="container">
		      <h1 className="masthead-headingWWC mb-0">ShePlaysUWCL 2023/24</h1>
          <p className="mb-0" style={{maginBottom:'1.5cm'}}>
          Compete against your friends in our fantasy UWCL competition!
          </p>
        {currentUser ? (
        <a href="/uwcl/my-team" className="btn btn-secondary btn-m rounded-pill mt-1">
          My UWCL Team </a>
) : (
        <a onClick={() => Auth.federatedSignIn()} href="#" className="btn btn-secondary btn-m rounded-pill mt-1">
          Get Started </a>)}

        </div>
      </div>
    </header>
<div className="container">
<a href='/uwcl/m/how-to-play' className="btn btn-teams btn-l rounded-pill mt-1" >
        How To Play
        </a>
<a href='/uwcl/m/fixtures' className="btn btn-teams btn-l rounded-pill mt-1" >
        Fixtures & Results
        </a>
<a href='/uwcl/teams' className="btn btn-teams btn-l rounded-pill mt-1" >
        Teams
        </a>
<a href='/uwcl/m/players' className="btn btn-teams btn-l rounded-pill mt-1" >
        Players
        </a>
<a href='/uwcl/m/leaderboard' className="btn btn-teams btn-l rounded-pill mt-1" >
        Leaderboard
        </a>
<a href='https://blog.sheplays.com.au/' className="btn btn-teams btn-l rounded-pill mt-1" >
        News
        </a>
<a href='/uwcl/join-us' className="btn btn-teams btn-l rounded-pill mt-1" >
        Join Our Team
        </a>
<a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-teams btn-l rounded-pill mt-1" >
        Support ShePlays on Ko-fi &nbsp; <SiKoFi/>
        </a>
</div>

        <div className="container">
            <p align="center" style={{marginTop:'0.5cm'}}><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white', marginLeft:'0.2cm', marginRight:'0.2cm'}}></a>
            <a href="https://instagram.com/sheplays_au" className="fa fa-instagram" style={{ color: 'white'}}></a></p>
        </div>

        <BottomNavBar />
</>     );
};

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 800;

  return width < breakpoint ? <UWCLLandingPageM /> : <UWCLLandingPageD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


