import React from "react";
import _ from "lodash";
import MyLeagues from "../leagues/MyLeagues";


const MyWSLLeagues = ({ route }) => {
  return (<>
    <div className="container my-3">
        <h1>WSL Mini Leagues</h1>
        <p>This is where you will be able to manage your mini-leagues, to play against your
          friends, family, and work colleagues! Once you have selected your fantasy team, you may
					join existing leagues or create your own!
      </p>
      
	  <MyLeagues route={route} competition="wsl" />
    
    </div>
<div className="container">
<p>Want to find public mini-leagues to test your fantasyWSL team against? Or want to learn about
some of the organisations that are doing great work to help grow women's football in the UK? 
	  Then check out the open mini-leagues
from some of our friends below!</p>

      <table className="table table-striped">
        <thead className="thead-dark">
<tr>
<th scope="col"></th>
<th scope="col">Name</th>
<th scope="col">Code</th>
<th scope="col"></th>
</tr>
</thead>

<tbody>
<tr>
<td><img hspace="20" src="/img/logo-round.png" width="40"/></td>
<td>ShePlays</td>
<td>sheplays</td>
<td>Compete against the ShePlays admin team</td>
</tr>
<tr>
<td><img hspace="20" src="/img/FFH-Logo.PNG" width="40"/></td>
<td>Fantasy Football Hub</td>
<td>FFH</td>
<td>Fantasy Football Hub is purpose-built to turn fantasy managers into fantasy winners, using their industry leading AI. FFH is expanding their offering of fantasy games to Women's football and so partnering with She Plays was essential!</td>
</tr>
<td><img hspace="20" src="/img/gotb2.jpg" width="40"/></td>
<td>Girls on the Ball</td>
<td>GOTB</td>
<td>Online platform promoting UK + Irish women's football.</td>
<tr>
<td><img hspace="20" src="/img/wsl/badges-team/ars.png" width="40"/></td>
<td>Arsenal Fans</td>
<td>Arsenal</td>
<td>For Fans of Arsenal</td>
</tr>
<tr>
<td><img hspace="20" src="/img/CWSG.jpg" width="40"/></td>
<td>Chelsea Women Supporters Group</td>
<td>CWSG</td>
<td>Home of Chelsea Women Supporters Group</td>
</tr>
</tbody>
</table> 
</div>

 </> );
};




export default MyWSLLeagues;


