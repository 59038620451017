import React from 'react';
import Flag from 'react-world-flags';
import { CompetitionWrapperUWCL } from '../../components/layout/CompetionWrapperUWCL';
import { uwcl } from '../../data/competitions/uwcl';

import moment from 'moment';
import numeral from 'numeral';

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};


const Image = ({uwclprofile}) => {
	if (!uwclprofile.noimg){
		return <figcaption align="center">
            Photo by <a href={uwclprofile.link2} >{uwclprofile.cap2}</a>, <a href={uwclprofile.link1} title="Creative Commons">{uwclprofile.cap1}</a>
            </figcaption>
	}
	return <span> </span>
	}

const Bio = ({uwclprofile}) => {
        if (!uwclprofile.nobio){
                return <p>Bio written by {uwclprofile.author} (<a href={uwclprofile.authTwitterL}>{uwclprofile.authTwitter}</a>)</p>
        }
        return <span> </span>
        }


const UWCLProfile = ({ uwclprofile }) => (<>
<CompetitionWrapperUWCL competition={uwcl}>
    <div className="container clearfix mt-2">
        <div className="float-left">
            <h1>{uwclprofile.name}</h1>
	    <p> </p>
        </div>
	<div className="container">
	<p></p>
	</div>
        <div className="float-right mr-2">
	    <img src={`/img/uwcl/badges/${uwclprofile.teamCode}.png`} width="100"/>
        </div>
    </div>
        <div className="container">
      <div className="row align-items-center">
        <div className="col-sm-6 order-sm-2">
          <div className="p-0">
            <div className="container">
	<p></p>
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
                    <th scope="col"></th>
                    <th scope="col" align="center"></th>
	        </tr>
            </thead>
	    <tbody>
	    <tr>
	        <td>Club</td>
	        <td align="center">{uwclprofile.teamName}</td>
	    </tr>
	    <tr>
	        <td>Position</td>
	        <td align="center">{uwclprofile.pos}</td>
	    </tr>
	    <tr>
	        <td>Nationality</td>
	        <td align="center"><img src={`/img/RoundFlags/${uwclprofile.nationality}.png`} width="40"/></td>
	    </tr>
	    <tr>
	        <td>Age</td>
	        <td align="center">{AGE(uwclprofile.dob)}
                 </td>
	    </tr>
	    <tr>
                <td>Social Media</td>
                <td align="center">{uwclprofile.socials}</td>
            </tr>
	    <tr>
	        <td>Fantasy Price</td>
	        <td align="center">{numeral(uwclprofile.price).format('$0,0')}</td>
	    </tr>
	    <tr>
	        <td>Fantasy Points</td>
	        <td align="center">{uwclprofile.score}</td>
	    </tr>
	    </tbody>
	    </table>
	    </div>
	  </div>
	  </div>
	 <div className="col-sm-6 order-sm-1">
	  <div className="p-0">
	    <img className="rounded mx-auto d-block mb-3" src={uwclprofile.img} width="450"/>
	 {Image({uwclprofile})}
	  </div>
	 </div>
       </div>
	<p style={{textAlign:'justify'}}>{uwclprofile.bio}</p>
	<p style={{textAlign:'justify'}}>{uwclprofile.bio2}</p>
	<p style={{textAlign:'justify'}}>{uwclprofile.bio3}</p>
	{Bio({uwclprofile})}
	<p><b></b> {uwclprofile.facts}</p>
      </div>

    <div className="container">
        <p> </p>
	<h4>Fantasy Points</h4>
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
	            <th scope="col"></th>
                    <th scope="col">MP</th>
                    <th scope="col">G</th>
                    <th scope="col">A</th>
                    <th scope="col">Att</th>
                    <th scope="col">Def</th>
                    <th scope="col">GK</th>
	            <th scope="col">Cards</th>
                    <th scope="col">CS</th>
                    <th scope="col">WDL</th>
                    <th scope="col">GD</th>
	            <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                {uwclprofile.stats2023.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>2023/24</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
            </tbody>

            <thead>
                <th scope="col" colspan='11'>Season 2024/25</th>
                <th scope="col"></th>
            </thead>
	    <tbody>
	        {uwclprofile.round1.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 1</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {uwclprofile.round2.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 2</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
	        {uwclprofile.round3.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 3</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {uwclprofile.round4.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 4</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {uwclprofile.round5.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 5</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {uwclprofile.round6.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 6</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {uwclprofile.round7.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 7</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {uwclprofile.round8.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 8</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {uwclprofile.round9.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 9</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
	        {uwclprofile.round10.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 10</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}
                {uwclprofile.round11.map(({MP,G,A,Att,Def,GK,Cards,CS,WDL,GD,Total}) =>
                <tr>
                    <td>Round 11</td>
                    <td>{MP}</td>
                    <td>{G}</td>
                    <td>{A}</td>
                    <td>{Att}</td>
                    <td>{Def}</td>
                    <td>{GK}</td>
                    <td>{Cards}</td>
                    <td>{CS}</td>
                    <td>{WDL}</td>
                    <td>{GD}</td>
                    <td>{Total}</td>
                </tr>
                )}

            </tbody>
            <thead>
                <th scope="col" colspan='11'>Total Points</th>
                <th scope="col">{uwclprofile.score}</th>
            </thead>
        </table>
    </div>
    <div className="container">
        <p></p>
    </div>
    </CompetitionWrapperUWCL>
</>)

export default UWCLProfile

