import React from 'react';
import moment from'moment';
import Flag from 'react-world-flags';
import { CompetitionWrapperUWCL } from "../../components/layout/CompetionWrapperUWCL";
import BottomNavBar from '../../components/layout/MCompetionWrapperUWCL.jsx';
import { uwcl } from "../../data/competitions/uwcl";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const teams = [
  {code: 'ars', name: 'Arsenal'},
  {code: 'fcb', name: 'Barcelona'},
  {code: 'bmu', name: 'Bayern Munich'},
  {code: 'cel', name: 'Celtic'},
  {code: 'che', name: 'Chelsea'},
  {code: 'gal', name: 'Galatasaray'},
  {code: 'ham', name: 'Hammarby'},
  {code: 'juv', name: 'Juventus'},
  {code: 'lyo', name: 'Lyon'},
  {code: 'mci', name: 'Manchester City'},
  {code: 'mad', name: 'Real Madrid'},
  {code: 'rom', name: 'Roma'},
  {code: 'pol', name: 'St. Pölten'},
  {code: 'twe', name: 'Twente'},
  {code: 'val', name: 'Vålerenga'},
  {code: 'wol', name: 'Wolfsburg'},
];

const Mteams = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
	{teams.map(team=> (
	<a
                href={`/uwcl/teams/m/${team.code}`}
                className="btn btn-teams btn-l rounded-pill mt-1" style={{alignSelf:'stretch'}}
              >
		{team.name}<img classname="rounded mx-auto d-block mb-3" align="right" height={30} src={`/img/uwcl/badges/${team.code.toUpperCase()}.png`}/>
              </a>
	))}
        </div>

<BottomNavBar/>
	
</>)

export default Mteams;
