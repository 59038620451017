import React, {useState, useEffect, useReducer, useRef} from "react";
import { Stage, Layer, Rect, Text, Arc, Image, Circle, Ellipse, useStrictMode, Shape } from "react-konva";
import Konva from "konva";
import useDimensions from "react-use-dimensions";
import { afl } from "../../data/sports/afl"


const imgUrls = (shirts) => [
  "/img/logo-square1.png",
  ...Object.values(shirts).map(({player}) => player),
  ...Object.values(shirts).map(({gk}) => gk)
];

const imageReducer = (state, {src, image}) => ({
  ...state,
  [src]: image
})

const PlayerImage = ({player: {club, id, name, position}, middle, size, length, i, y, images, shirts}) => {
  const x = middle - (size * 2) * (((length-1)/2) - i);
  console.log(club);
  const image = position === 'GK' ? shirts[club].gk : shirts[club].player;
  return (
    <>
      <Image key={id} x={x} y={y} image={images[image]} width={size} height={size} offsetX={size/2} />
      <Text key={`${id}-text`} x={x} y={y + 1.1*size} text={name} align="center" width={2 * size} offsetX={size} fill="#ffffff" fontSize={size/6} fontFamily="lato" />
    </>
  )
}
export const DisplayTeam = ({ sport, ...props }) => sport === afl ? DisplayTeamAFL(props) : DisplayTeamSoccer(props)

export const DisplayTeamSoccer = ({ players, teamName, shirts, hashtag }) => {
  useStrictMode(true);
  const [ref, { width: divWidth }] = useDimensions();
  const stage = useRef(null);
  const [images, addImage] = useReducer(imageReducer, {});
  const [downloadUrl, setDownloadUrl] = useState(null);

  const width = 1038;
  const height = 960;
  const scale = divWidth/width;
  console.log(scale);
  const middle = width / 2;
  const fieldWidth = width - 20;
  const fieldHeight = (width * 0.6) - 20;

  const heightUnit = Math.floor(fieldHeight / 24);
  const halfUnit = Math.floor(heightUnit / 2);
  const yOffset = heightUnit * 4;
  const playerHeight = heightUnit * 4;

  const playersOnField = players.slice(0, 11);
  const playersOnBench = players.slice(11, 15);

  useEffect(() => {
    imgUrls(shirts).forEach(src => {
      const image = new window.Image();
      image.src = src;
      image.onload = () => addImage({src, image})
    });
  }, [addImage]);

  useEffect(() => {
    const options = {pixelRatio: 1/scale};
    setDownloadUrl(stage.current && stage.current.getStage() && stage.current.getStage().toDataURL(options))
  }, [images, players]);

  if(!players.length || !divWidth) {
    return <div className="w-100" ref={ref} />
  }
  return (
    <div className="w-100" ref={ref}>
      <Stage width={divWidth} height={height*scale} ref={stage} scaleX={scale} scaleY={scale}>
        <Layer>
          <Rect x={middle} y={0} width={width} offsetX={width/2} height={height} fill={"#008a5b"} />

          <Text x={middle} y={heightUnit} text={teamName} align="center" width={fieldWidth} offsetX={fieldWidth/2} fontSize={playerHeight/2} fontStyle="bold" fill="#ffffff" fontFamily='Catamaran'/>

          <Rect x={middle} y={yOffset} width={fieldWidth} offsetX={fieldWidth/2} height={fieldHeight} stroke="#ffffff" />
          <Rect x={middle} y={yOffset} width={0.6*fieldWidth} offsetX={0.3*fieldWidth} height={0.24*fieldWidth} fill={"#008a5b"} stroke="#ffffff" />
          <Rect x={middle} y={yOffset} width={3/11*fieldWidth} offsetX={3/22*fieldWidth} height={0.08*fieldWidth} stroke="#ffffff" />
          <Image x={10 + halfUnit} y={yOffset +halfUnit} image={images["/img/logo-square1.png"]} width={heightUnit*6} height={heightUnit*2} config={{
            imageSmoothingEnabled: true
          }} />
          {playersOnField.filter(({position}) => position === "GK").map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle, size: playerHeight, length, y: yOffset + heightUnit, images, i }} />
          ))}

          {playersOnField.filter(({position}) => position === "DF").map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle, size: playerHeight, length, y: yOffset + 7 * heightUnit, images, i }} />
          ))}

          {playersOnField.filter(({position}) => position === "MF").map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle, size: playerHeight, length, y: yOffset + 13 * heightUnit, images, i }} />
          ))}

          {playersOnField.filter(({position}) => position === "FW").map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle, size: playerHeight, length, y: yOffset + 19 * heightUnit, images, i }} />
          ))}

          {playersOnBench.map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle, size: playerHeight, length, y: yOffset + fieldHeight + 10 + halfUnit + heightUnit, images, i }} />
          ))}
          <Text x={middle} y={yOffset + fieldHeight + 10} text="Bench" align="center" width={fieldWidth} offsetX={fieldWidth/2} fontSize={heightUnit} fontStyle="bold" fill="#ffffff" fontFamily='Catamaran' />
          <Text x={0} y={height - 10 - heightUnit} text={`#${hashtag}`} align="right" width={width-10} fontSize={heightUnit} fontStyle="bold" fill="#ffffff" fontFamily='Catamaran' />
        </Layer>
      </Stage>
      <a className="pull-right" href={downloadUrl} download={`ShePlays - ${teamName}.png`}>⬇ Download as image</a>
    </div>

  );
};

export const DisplayTeamAFL = ({ players, teamName, shirts, hashtag }) => {
  useStrictMode(true);
  const [ref, { width: divWidth }] = useDimensions();
  const stage = useRef(null);
  const [images, addImage] = useReducer(imageReducer, {});
  const [downloadUrl, setDownloadUrl] = useState(null);

  const width = 1038;
  const height = 1180;
  const scale = divWidth/width;
  console.log(scale, width);
  const middle = width * 0.5;
  const fieldMiddle = width * 0.4;
  const fieldWidth = (width - 20) * 0.8;
  const oldHeight = (width * 0.6) - 20;
  const fieldHeight = fieldWidth * 1.3;

  const heightUnit = Math.floor(oldHeight / 24);
  const halfUnit = Math.floor(heightUnit / 2);
  const yOffset = heightUnit * 6;
  const playerHeight = heightUnit * 4;

  const playersOnField = players.slice(0, 16);
  const playersOnBench = players.slice(16, 21);

  useEffect(() => {
    imgUrls(shirts).forEach(src => {
      const image = new window.Image();
      image.src = src;
      image.onload = () => addImage({src, image})
    });
  }, [addImage]);

  useEffect(() => {
    const options = {pixelRatio: 1/scale};
    setDownloadUrl(stage.current && stage.current.getStage() && stage.current.getStage().toDataURL(options))
  }, [images, players]);

  if(!players.length || !divWidth) {
    return <div className="w-100" ref={ref} />
  }
  return (
    <div className="w-100" ref={ref}>
      <Stage width={divWidth} height={height*scale} ref={stage} scaleX={scale} scaleY={scale}>
        <Layer>
          <Rect x={0} y={0} width={width} height={height} fill={"#008a5b"} />

          <Circle x={fieldMiddle} y={yOffset} radius={fieldWidth * 0.4} stroke="#ffffff" />
          <Circle x={fieldMiddle} y={yOffset - 40 + (fieldWidth * 0.6 )+ (fieldHeight/2)} radius={fieldWidth * 0.4} stroke="#ffffff" />

          <Shape fill="#008a5b" sceneFunc={(ctx, shape) => {
            ctx.beginPath();
            ctx.moveTo(0,0);
            ctx.lineTo(width, 0);
            ctx.lineTo(width, height);
            ctx.lineTo(0, height);
            ctx.lineTo(0,0);
            
            ctx._context.ellipse(fieldMiddle, yOffset + (fieldWidth * 0.6), fieldWidth /2, fieldHeight/2, 0, 0, Math.PI * 2, true);
            ctx.fillStrokeShape(shape);
          }}/>

          <Ellipse x={fieldMiddle} y={yOffset + fieldWidth * 0.6} width={fieldWidth} height={fieldHeight} stroke="#ffffff" />
          <Image x={10} y={yOffset - heightUnit * 3} image={images["/img/logo-square1.png"]} width={heightUnit*6} height={heightUnit*6} config={{
            imageSmoothingEnabled: true
          }} />
          <Circle x={10 + heightUnit * 3} y={heightUnit * 6} radius={heightUnit*3} stroke="#ffffff" />

          <Text x={middle} y={heightUnit} text={teamName} align="center" width={fieldWidth} offsetX={fieldWidth/2} fontSize={playerHeight/2} fontStyle="bold" fill="#ffffff" fontFamily='Catamaran'/>
          

          {playersOnField.filter(({position}) => position === "DEF").slice(0, 2).map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle: fieldMiddle, size: playerHeight, length, y: yOffset, images, i }} />
          ))}

          {playersOnField.filter(({position}) => position === "DEF").slice(2).map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle: fieldMiddle, size: playerHeight, length, y: yOffset + 7 * heightUnit, images, i }} />
          ))}

          {playersOnField.filter(({position}) => position === "MID").slice(0, 3).map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle: fieldMiddle, size: playerHeight, length: length + 1, y: yOffset + 14 * heightUnit, images, i: i*1.5 }} />
          ))}

          {playersOnField.filter(({position}) => position === "MID").slice(3).map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle: fieldMiddle, size: playerHeight, length: length + 0.5, y: yOffset + 17 * heightUnit, images, i: i*1.5 }} />
          ))}

          {playersOnField.filter(({position}) => position === "RUCK").map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle: fieldMiddle, size: playerHeight, length, y: yOffset + 20 * heightUnit, images, i }} />
          ))}

          {playersOnField.filter(({position}) => position === "FWD").slice(0, 3).map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle: fieldMiddle, size: playerHeight, length, y: yOffset + 27 * heightUnit, images, i }} />
          ))}

          {playersOnField.filter(({position}) => position === "FWD").slice(3).map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle: fieldMiddle, size: playerHeight, length, y: yOffset + 33.5 * heightUnit, images, i }} />
          ))}


          {playersOnBench.map((player, i, {length}) => (
            <PlayerImage shirts={shirts} key={player.id} {...{ player, middle: 0.9*width, size: playerHeight, length: 1, y: yOffset + heightUnit * (3 + 7 * i), images, i: 0 }} />
          ))}

          <Text x={width * 0.8} y={yOffset + 10} text="Bench" align="center" width={width * 0.2} fontSize={heightUnit} fontSt7yle="bold" fill="#ffffff" fontFamily='Catamaran' />
          <Text x={0} y={height - 10 - heightUnit} text={`#${hashtag}`} align="right" width={width-10} fontSize={heightUnit} fontStyle="bold" fill="#ffffff" fontFamily='Catamaran' />
        </Layer>
      </Stage>
      <a className="pull-right" href={downloadUrl} download={`ShePlays - ${teamName}.png`}>⬇ Download as image</a>
    </div>

  );
};
