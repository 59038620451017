import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"; 
import { useAuth } from "../../util/withAuth";

const teams = [
  {code: "ars", name: "Arsenal"},
  {code: "ast", name: "Aston Villa"},
  {code: "brh", name: "Brighton & Hove Albion"},
  {code: "che", name: "Chelsea"},
  {code: "cry", name: "Crystal Palace"},
  {code: "eve", name: "Everton"},
  {code: "lei", name: "Leicester City"},
  {code: "liv", name: "Liverpool"},
  {code: "mci", name: "Manchester City"},
  {code: "mun", name: "Manchester United"},
  {code: "tot", name: "Tottenham Hotspur"},
  {code: "whu", name: "West Ham United"}
]

export const CompetitionWrapperWSL = ({ competition, children }) => {
  const [teamDropdown, setTeamDropdown] = useState(false, []); 
  const [newsDropdown, setNewsDropdown] = useState(false, []);
  const [playerDropdown, setPlayerDropdown] = useState(false, []);
  const [oldDropdown, setoldDropdown] = useState(false, []);
  const currentUser = useAuth()

  return (
    <div className={`competition-${competition.shortName}`}>
      <nav className="navbar navbar-expand-lg navbar-custom navbar-wsl">
        <div className="container">
          <ul className="navbar-nav mr-5">
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/how-to-play`}
              >
                Rules &amp; Scoring
              </a>
            </li>
           <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/fixtures`}
              >
              Fixtures & Results
              </a>
            </li> 
            <li className="nav-item">
            <Dropdown
              isOpen={teamDropdown}
              toggle={() => setTeamDropdown(!teamDropdown)}
            >
              <DropdownToggle className="nav-link" caret>
                Teams
              </DropdownToggle>
              <DropdownMenu style={{ position: 'absolute', zIndex: '3000' }}>
                {teams.map(team => (
                  <DropdownItem
                    key={team.code}
                    href={`/${competition.shortName}/teams/${team.code ||
                      team.code}`.toLowerCase()}
                  >
                    {team.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            </li>
            <li className="nav-item" >
              <Dropdown
                isOpen={playerDropdown}
                toggle={() => setPlayerDropdown(!playerDropdown)}
              >
                <DropdownToggle className="nav-link" caret>
                  Players
                </DropdownToggle>
                <DropdownMenu style={{ position: 'absolute', zIndex: '3000' }}>
                  <DropdownItem href="/wsl/database">Player Database</DropdownItem>
	          <DropdownItem href="https://blog.sheplays.com.au/wsl-transfers/">Transfer Center</DropdownItem>
	          <DropdownItem href="/wsl/stats">Stats Center</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
	  <li className="nav-item">
              <Dropdown
                isOpen={oldDropdown}
                toggle={() => setoldDropdown(!oldDropdown)}
              >
                <DropdownToggle className="nav-link" caret>
                  Past Seasons
                </DropdownToggle>
                <DropdownMenu style={{ position: 'absolute', zIndex: '3000' }}>
	          <DropdownItem href="/wsl/all-players">All-Seasons Player Database</DropdownItem>
                  <DropdownItem header>2023/24 Season</DropdownItem>
                  <DropdownItem href="/wsl/23/profiles">Player Database</DropdownItem>
                  <DropdownItem href="/wsl/23/stats">Stats Center</DropdownItem>
                  <DropdownItem href="/wsl/23/leaderboard">Leaderboard</DropdownItem>
                  <DropdownItem divider />
	          <DropdownItem header>2022/23 Season</DropdownItem>
	          <DropdownItem href="/wsl/22/profiles">Player Database</DropdownItem>
                  <DropdownItem href="/wsl/22/stats">Stats Center</DropdownItem>
                  <DropdownItem href="/wsl/22/leaderboard">Leaderboard</DropdownItem>
                  <DropdownItem divider />
	          <DropdownItem header>2021/22 Season</DropdownItem>
	          <DropdownItem href="/wsl/21/profiles">Player Database</DropdownItem>
	          <DropdownItem href="/wsl/21/stats">Stats Center</DropdownItem>
	          <DropdownItem href="/wsl/21/leaderboard">Leaderboard</DropdownItem>
	          <DropdownItem divider />
	          <DropdownItem header>2020/21 Season</DropdownItem>
                  <DropdownItem href="/wsl/20/profiles">Player Database</DropdownItem>
                  <DropdownItem href="/wsl/20/stats">Stats Center</DropdownItem>
                  <DropdownItem href="/wsl/20/leaderboard">Leaderboard</DropdownItem>
                  <DropdownItem divider />
	          <DropdownItem header>2019/20 Season</DropdownItem>
                  <DropdownItem href="/wsl/19/profiles">Player Database</DropdownItem>
                  <DropdownItem href="/wsl/19/stats">Stats Center</DropdownItem>
                  <DropdownItem href="/wsl/19/leaderboard">Leaderboard</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
	    <li className="nav-item">
             <a
              className="nav-link"
              href="/wsl/leaderboard"
             >
              Leaderboard
             </a>
            </li>
	    <li className="nav-item">
	     <a 
	      className="nav-link"
	      href="https://blog.sheplays.com.au/"
	     >
	      Latest News
	     </a>
	    </li>
            {currentUser &&
            <li className="nav-item">
              <a className="nav-link" href={`/${competition.shortName}/my-team`}>
                My Team
              </a>
            </li>
            }
          </ul>
        </div>
      </nav>
      {children}
    </div>
  );
};
